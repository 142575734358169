<template>
  <div>
    <div class="theSteps">
      <el-steps :active="active" finish-status="success">
        <el-step title="上传导入数据" icon="el-icon-upload2" />
        <el-step title="数据预览" icon="el-icon-s-order" />
        <el-step title="数据导入完成" icon="el-icon-s-claim" />
      </el-steps>
    </div>
    <div class="center">
      <span style="fontSize:22px">导入情况！</span>
    </div>
    <div class="center">
      <div style="margin:12px">
        <span>共{{failQty+successQty}}条数据，成功导入{{successQty}}条！</span>
      </div>
      <div>
         <el-button
              type="text"
              v-show="content"
              icon="el-icon-download"
              @click="exportFu2"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >下载失败列表</el-button
            >
      </div>
    </div>
     <div style="margin: 5% 0;display: flex;justify-content: center;">
      <el-button type="primary" @click="submitForm">到库存信息页面</el-button>
      <!-- <el-button @click="resetForm">上一步</el-button> -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
   props: {
    fileContent:Object
  },
  data() {
    return {
      active: 3,
      content:'',
      successQty:0,
      failQty:0
    };
  },

  methods: {
    next() {
      if (this.active++ > 2) this.active = 0;
    },
     submitForm() {
      this.$router.push({path:'index'})
    },
    resetForm() {
      // this.$router.push({ path: "secondUploadStock" });
      if(window.history.length>1){
        this.$router.back();
      }else{
        this.$router.push('index');
      }
    },
    exportFu2() {
        const a = document.createElement("a");
        a.style.display = "none";
        a.download = this.fileContent.failFileName;
        a.href = `${request.defaults.baseURL}/excel/${this.fileContent.failFileName}`;
        document.body.appendChild(a);
        a.click(); // 自动触发点击a标签的click事件
        document.body.removeChild(a);
    },
  },
   mounted() {
    if (this.fileContent.failQty!=0) {
      this.content='true'
    }
    // console.log(this.fileContent);
    this.successQty=Number(this.fileContent.successQty)
    this.failQty=Number(this.fileContent.failQty)
    
  },
};
</script>
<style scoped>
.theSteps {
  width: 700px;
  margin: 50px auto;
}
/* 进行中状态：圈线 */
.theSteps >>> .el-step__head.is-process {
  color: #409EFF;
  border-color: #409EFF;
}
/* 进行中状态：圈内 */
.theSteps >>> .el-step__head.is-process > .el-step__icon {
  background: #fff;
  color: #409EFF;
}
/* 进行中状态：title（文字） */
.theSteps >>> .el-step__title.is-process {
  color: #409EFF;
}


/* 完成状态：圈线 */
.theSteps >>> .el-step__head.is-success {
  color: #409EFF;
  border-color: #409EFF;
}
/* 完成状态：title（文字） */
.theSteps >>> .el-step__title.is-success {
  color: #409EFF;
}
.center {
  margin: 5% 0;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>
